.page-wrapper {
  @mixin breakpoints-up($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
      @content;
    }
  }

  @mixin breakpoints-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
      @content;
    }
  }
}
