:root {
  --clr-neutral-0: #ffffff00;
  /* transparent */
  --clr-neutral-100: #ffffff;
  --clr-neutral-300: #cccccc;
  --clr-neutral-700: #5f6368;

  --gradient-card-bg: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  --gradient-card-bdr: linear-gradient(
    100deg,
    var(--clr-neutral-100),
    var(--clr-neutral-0)
  );

  --card-width: 440px;
  --card-gaps: 20px;
  --card-bdr: 1.5px solid #ccc;
  --card-bdr-image: linear-gradient;
  --card-bdr-radius: 15px;
  --card-padding: var(--card-gaps);

  --card-title-clr: var(--clr-neutral-100);
  --card-title-size: 22px;
  --card-title-lheight: 33px;

  --card-category-clr: var(--clr-neutral-300);
  --card-category-size: 13px;
  --card-category-lheight: 19.5px;

  --card-image-mb: var(--card-gaps);
  --card-info-mb: var(--card-gaps);

  --author-image-size: 63px;
  --author-image-bdr-radius: 10px;
}

.card {
  position: relative;
  width: var(--card-width);
  border: 1px solid var(--clr-neutral-700);
  /*border-image: var(--gradient-card-bdr) 1;*/
  border-radius: var(--card-bdr-radius);
  padding: var(--card-padding);
  background: var(--gradient-card-bg);
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  /* backdrop-filter: blur(25px); */
}

.card-image {
  margin-bottom: var(--card-image-mb);
  border-radius: var(--card-bdr-radius);
  overflow: hidden;
}

.card-image img {
  transition: all 0.2s ease-in-out;
  transform-origin: right top;
}

.card-image:hover img {
  transform: scale(1.1);
  transform-origin: center center;
}

.card-info > div {
  margin-bottom: var(--card-info-mb);
  display: flex;
  align-items: center;
}

.card-info .content {
  gap: var(--card-gaps);
  display: flex;
  justify-content: space-between;
}

.card-info .author-image,
.card-info .card-coin-icon {
  display: block;
  width: var(--author-image-size);
  height: var(--author-image-size);
  border-radius: var(--author-image-bdr-radius);
}

.card-info .author-image {
  background: mediumslateblue;
}

.card-info .card-coin-icon {
  /* margin-left: auto; */
  background: orchid;
}

.card-info .card-title {
  font-size: var(--card-title-size);
  line-height: var(--card-title-lheight);
  color: var(--card-title-clr);
}

.card-info .card-category {
  font-size: var(--card-category-size);
  line-height: var(--card-category-lheight);
  color: var(--card-category-clr);
}

.card-info .prices {
  justify-content: space-between;
}

.card-info .price {
  display: flex;
  flex-direction: column;
}

.card-actions {
  display: flex;
  justify-content: center;
}