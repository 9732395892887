// useful classes
.page-wrapper {
  .container {
    max-width: 1440px;
    padding: 0 18px;
    margin: 0 auto;
    width: 100%;
  }

  // flexbox and grid
  .flex {
    display: flex;
    &-col {
      flex-direction: column;
    }
    &-wrap {
      flex-wrap: wrap;
    }
    &-inline {
      display: inline-flex;
    }
    &-1 {
      flex: 1;
    }
  }

  .items {
    &-center {
      align-items: center;
    }
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-stretch {
      align-items: stretch;
    }
  }

  .justify {
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
    }
    &-start {
      justify-content: flex-start;
    }
    &-end {
      justify-content: flex-end;
    }
  }

  .grid {
    display: grid;
  }

  // height and width
  .h-full {
    height: 100%;
  }
  .w-full {
    width: 100%;
  }

  // text align and transformation
  .text {
    &-center {
      text-align: center;
    }
    &-start {
      text-align: left;
    }
    &-end {
      text-align: right;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }

  // text color
  .text-white {
    color: $white;
  }
  .text-lavender {
    color: $lavender;
  }
  .text-mint {
    color: $mint;
  }
  .text-light-red {
    color: $light-red;
  }
  .text-gray {
    color: $cool-grey;
  }

  // backgrounds
  .bg {
    &-white {
      background-color: $white;
    }
    &-dark {
      background-color: $dark;
    }
  }

  // gradents
  .grad-blue-veronica {
    background: $veronica-gradient;
  }

  .object-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-wrap {
    white-space: nowrap;
  }

  .bg-overlay {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .text {
    font-weight: 400;
    color: $cool-grey;
    line-height: 150%;
    font-family: "Poppins", sans-serif;
  }

  .large-title {
    font-size: 50px;
    font-weight: 900;
    line-height: 1.4;

    @include breakpoints-down(lg) {
      font-size: 36px;
    }
    @include breakpoints-down(sm) {
      font-size: 28px;
    }
    @include breakpoints-down(xs) {
      font-size: 24px;
    }
  }

  // animation and transition stopper
  .resize-animation-stopper * {
    animation: none !important;
    transition: none !important;
  }
}
