
.stack__card {
  /* local-shadow-color: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: sticky;
  top: 0;
  box-shadow: 0 -1px 3px rgba(local-shadow-color, 0.1),
    0 -1px 2px rgba(local-shadow-color, 0.25);
  font-size: 10rem;
}

.stack__card:nth-child(1) {
  background-color: #e3342f;
}

.stack__card:nth-child(2) {
  background-color: #38c172;
}

.stack__card:nth-child(3) {
  background-color: #3490dc;
}

.stack__card:nth-child(4) {
  background-color: #f66d9b;
}

.stack__card:nth-child(5) {
  background-color: #f6993f;
}
