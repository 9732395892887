@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  :root {
    --black-gradient: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }

  * {
    scroll-behavior: smooth;
  }

  .text-gradient {
    background: radial-gradient(
      64.18% 64.18% at 71.16% 35.69%,
      #fff0d8 0.89%,
      #fedfb2 17.23%,
      #ffd28f 42.04%,
      #ffc979 55.12%,
      #ffae35 71.54%,
      #ffa319 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .bg-blue-gradient {
    background: linear-gradient(
      157.81deg,
     #fff0d8 -43.27%,
     #fedfb2 -21.24%,
     #ffd28f 12.19%,
     #ffc979 29.82%,
     #ffae35 51.94%,
     #ffa319 90.29%
    );
  }

  .bg-black-gradient {
    background: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
  }

  .bg-black-gradient-2 {
    background: linear-gradient(
      -168.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
  }

  .bg-gray-gradient {
    background: linear-gradient(
      153.47deg,
      rgba(255, 255, 255, 0) -341.94%,
      #14101d 95.11%
    );
  }

  .bg-discount-gradient {
    background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
  }

  .box-shadow {
    box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }

  .sidebar {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .feature-card:hover {
    background: var(--black-gradient);
    box-shadow: var(--card-shadow);
  }

  .feedback-container .feedback-card:last-child {
    margin-right: 0px;
  }

  .feedback-card {
    background: transparent;
  }

  .feedback-card:hover {
    background: var(--black-gradient);
  }

  .blue__gradient {
    background: linear-gradient(
      180deg,
      rgba(188, 165, 255, 0) 0%,
      #214d76 100%
    );
    filter: blur(123px);
    /* to force the browser use GPU acceleration for that particular element instead of the CPU. */
    transform: translate3d(0, 0, 0);
  }

  .pink__gradient {
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(900px);
    /* to force the browser use GPU acceleration for that particular element instead of the CPU. */
    transform: translate3d(0, 0, 0);
  }

  .white__gradient {
    background: rgba(255, 255, 255, 0.6);
    filter: blur(750px);
    /* to force the browser use GPU acceleration for that particular element instead of the CPU. */
    transform: translate3d(0, 0, 0);
  }

  .shadowfilter {
    -webkit-transition: drop-shadow 0.3s ease-in-out;
    -o-transition: drop-shadow 0.3s ease-in-out;
    transition: drop-shadow 0.3s ease-in-out;
  }

  .shadowfilter:hover {
    -webkit-filter: drop-shadow(0px 0px 8px rgba(188, 165, 255, 0.3));
    filter: drop-shadow(0px 0px 8px rgba(188, 165, 255, 0.3));
  }

  .get-started {
    padding: 2px;
    animation: breathing 3s ease-in-out infinite;
  }

  @keyframes breathing {
    0% {
      padding: 2px;
      -webkit-filter: drop-shadow(0px 0px 8px rgba(188, 165, 255, 0.3));
    }
    50% {
      -webkit-filter: drop-shadow(0px 0px 8px rgba(188, 165, 255, 0.3));
      -webkit-filter: drop-shadow(0px 0px 16px rgba(188, 165, 255, 0.3));
      -webkit-filter: drop-shadow(0px 0px 24px rgba(188, 165, 255, 0.3));

      padding: 5px;
    }
    100% {
      -webkit-filter: drop-shadow(0px 0px 8px rgba(188, 165, 255, 0.3));

      padding: 2px;
    }
  }

  /* form errors */
  input:invalid ~ span {
    display: block;
  }

  input:invalid[focused="true"] {
    border-color: red;
    color: red;
  }
  
  .scaleX {
    transform: scaleX(-1) !important;
  }
}
